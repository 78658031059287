import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['input', 'errorMessage', 'wordCounter'];
  static values = { required: Boolean, maxWords: Number, label: String }

  connect() {
    this.inputTarget.addEventListener("trix-change", function() { this.onTrixChange() }.bind(this));
    this.inputTarget.addEventListener("trix-initialize", function() { this.onTrixInit() }.bind(this));

    this.invalid = false;
    this.errorMessage = ""

    if(this.inputTarget.classList.contains("is-invalid")) {
      this.validate();
    }
  }

  onTrixInit() {
    this.updateHeight();
    this.updateWordCount();
  }

  onTrixChange() {
    this.updateWordCount();
    this.validate();
    this.updateHeight();
  }

  validate() {
    if(this.requiredValue && !this.inputTarget.hasChildNodes()) {
      this.errorMessage = `${this.labelValue} is required.`;
      this.invalid = true;
    } else if(this.maxWordsValue >= 0 && this.wordCount > this.maxWordsValue) {
      this.errorMessage = `${this.labelValue} should be ${this.maxWordsValue} words or less.`;
      this.invalid = true;
    }
    else {
      this.invalid = false;
    }

    this.errorMessageTarget.textContent = this.errorMessage;
    if (this.invalid) {
      this.inputTarget.classList.add("is-invalid");
      this.errorMessageTarget.classList.remove("d-none");
    } else {
      this.inputTarget.classList.remove("is-invalid");
      this.errorMessageTarget.classList.add("d-none");
    }      
  }

  updateWordCount() {
    if (this.maxWordsValue > 0) {
      var words = this.inputTarget.textContent.split(/[\s\.\?]+/);
      this.wordCount = words.filter(function(str) { return /\S/.test(str); }).length;
      this.wordCounterTarget.textContent = this.wordCount + "/" + this.maxWordsValue;
    }
  }

  /* Auto expand height to match contents */
  updateHeight() {
    this.inputTarget.style.height = this.inputTarget.scrollHeight;
  }

}